module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KaiOS","short_name":"KaiOS","start_url":"/","background_color":"#fff","theme_color":"#6f02b5","display":"minimal-ui","icon":"/builds/adtech/website-ui/src/images/KaiOS-Icon-32x32.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
